<template>
  <span
    class="text-subtitle-1 font-weight-bold"
  >
    <slot />
  </span>
</template>

<script>
  export default {
    name: 'BasePresentationHeading',
  }
</script>
