<template>
  <section
    id="projects"
    v-observe-visibility="{
      callback: (isVisible, entry) => changeAnchor(isVisible, entry),
      intersection: {
        threshold: 0.5
      },
    }"
    class="overflow-hidden accent py-10"
  >
    <v-row
      justify="center"
      align="center"
      no-gutters
    >
      <v-col cols="10">
        <div class="text-center mx-auto">
          <base-heading class="info--text">
            Réalisations
          </base-heading>
          <base-text class="py-3">
            Parce qu'une image vaut mille mots, voici une sélection de projets entrepris par <i>Pierre FACQ Business</i>.
          </base-text>
        </div>

        <v-row
          class="pa-10"
          justify="space-around"
        >
          <v-col
            v-for="project in projects"
            :key="project.title"
            cols="12"
            md="6"
          >
            <v-hover
              v-slot="{ hover }"
              style="cursor: pointer"
              @click.native="showModal(project)"
            >
              <v-lazy
                :id="project.folder"
                :options="{
                  threshold: .3,
                }"
                transition="scroll-y-reverse-transition"
              >
                <v-card
                  :elevation="hover ? 8 : 2"
                  :style="{
                    transform: hover ? 'scale(1.05)' : '',
                    transition: 'transform 330ms ease-in-out'
                  }"
                >
                  <v-img
                    :src="require(`@/assets/projects/${project.folder}/${project.previewImage}`)"
                    max-height="400"
                    :aspect-ratio="16/9"
                  >
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        class="pa-5"
                        absolute
                      >
                        <base-heading v-html="project.title" />
                        <base-subheading v-html="project.company" />
                        <base-text v-html="project.previewText" />
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </v-card>
              </v-lazy>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      v-if="displayModal"
      id="projectModal"
      opacity="0.9"
    >
      <v-img
        v-if="showImage"
        :src="require(`@/assets/${imageLink}`)"
        :style="{
          position: 'fixed',
          height: '100%',
          width: '100%',
          cursor: 'zoom-out',
        }"
        contain
        @click="hideImageFullScreen"
      />
      <v-row
        v-show="!showImage"
        class="py-5 mx-0"
        justify="center"
      >
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : (modalProject.video ? 8 : 10)">
          <v-carousel
            v-if="modalProject.images"
            cycle
          >
            <v-carousel-item
              v-for="(item,i) in modalProject.images"
              :key="i"
              :src="require(`@/assets/projects/${modalProject.folder}/${item.src}`)"
              style="cursor: zoom-in"
              contain
              @click="showImageFullScreen(modalProject.folder, item.src)"
            />
          </v-carousel>

          <div
            v-if="modalProject.video"
            style="position:relative; overflow: hidden; width:100%; padding-top:56.250%;"
            v-html="modalProject.video"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-sheet
            class="pa-5"
            elevation="2"
            outlined
            rounded
          >
            <h2 class="text-h5 text-center">
              {{ modalProject.title }}
            </h2>
            <base-presentation-heading>Client</base-presentation-heading>
            <p class="blockquote">
              {{ modalProject.company }}
            </p>
            <base-presentation-heading>Année de réalisation</base-presentation-heading>
            <p class="blockquote">
              {{ modalProject.year }}
            </p>
            <base-presentation-heading>Problématique</base-presentation-heading>
            <p
              class="blockquote"
              v-html="modalProject.presentationText"
            />
            <base-presentation-heading>Solution existante</base-presentation-heading>
            <p
              class="blockquote"
              v-html="modalProject.beforeText"
            />
            <base-presentation-heading>Besoin</base-presentation-heading>
            <p
              class="blockquote"
              v-html="modalProject.needText"
            />
            <base-presentation-heading>Solution développée par Pierre FACQ Business</base-presentation-heading>
            <p
              class="blockquote"
              v-html="modalProject.afterText"
            />
            <base-presentation-heading>Aujourd'hui</base-presentation-heading>
            <p
              class="blockquote"
              v-html="modalProject.todayText"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          class="text-center"
        >
          <base-btn @click="hideModal()">
            fermer
          </base-btn>
        </v-col>
        <v-btn
          id="closeBtn"
          icon
          @click="hideModal()"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-row>
    </v-overlay>
  </section>
</template>

<script>
  import { nav } from '@/components/mixins/nav'
  import BasePresentationHeading from '@/components/base/PresentationHeading'

  export default {
    name: 'Realisations',
    components: { BasePresentationHeading },
    mixins: [nav],
    data: () => ({
      displayModal: false,
      showImage: false,
      imageLink: null,
      modalProject: {},
      projects: [
        {
          title: 'BIPE',
          company: 'GRDF',
          year: '2020',
          previewText: 'Gestion et programmation du parcours client pour l\'installation gaz',
          folder: 'bipe',
          previewImage: 'accueil.png',
          video: '<iframe src="https://streamable.com/e/l29q4l" allowfullscreen style="position:absolute;left:0;top:0;bottom:0;right:0;width:100%;height:100%;border:0;"></iframe>',
          presentationText: 'Chaque jour en France, des foyers demandent à être reliés au réseau gaz.<br>' +
            'C\'est dans ce contexte qu\'intervient GRDF et plus particulièrement' +
            ' la cellule Programmation du Parcours Client (PPC).<br>' +
            'Cette cellule a pour mission de mettre en place le plan d\'action nécessaire afin d\'acheminer le client en gaz,' +
            ' suivant les besoins spécifiques du client.<br>' +
            'Elle doit aussi assurer le suivi du dossier entre les différents services sollicités.<br>' +
            'Enfin la cellule est chargée de récupérer les retours client, une fois les travaux terminés et le client raccordé.',
          beforeText: 'Dans le but d\'automatiser et d\'organiser ce processus au mieux,' +
            ' la région Est a mis au point l\'application web interne "BIPE" au milieu des années 2000.<br>',
          needText: 'Cependant des mauvais choix lors de la conception rendaient l\'application difficilement maintenable et évolutive.<br>' +
            'De plus le serveur sur lequel était hébergé l\'outil allait fermer sous peu.<br><br>' +
            'C\'est pourquoi GRDF a souhaité une refonte intégrale de "BIPE" ainsi qu\'une bascule temporaire de l\'ancienne version' +
            ' sur un nouveau serveur afin de garder la cellule PPC active le temps de la refonte de la nouvelle application.<br>' +
            'À cette refonte s\'est ajoutée la demande d\'une partie enquête satisfaction afin de récupérer et stocker l\'avis client.<br>' +
            'En dernier lieu, et afin d\'avoir plus de visibilité sur l\'organisation des travaux,' +
            ' GRDF a demandé la conception d\'un diagramme de Gantt dynamique.',
          afterText: 'L\'urgence a d\'abord été pour Pierre FACQ Business de déplacer l\'ancienne application "BIPE" sur un nouveau serveur.<br>' +
            'Cette étape a été réalisée avec succès après la mise à niveau des différents langages de l\'application.<br><br>' +
            'Puis a été conçue la nouvelle application web interne PHP avec entre autres:' +
            '<ul>' +
            '<li>toutes les fonctionnalités de la version historique (pour la plupart améliorées)</li>' +
            '<li>une ergonomie et un visuel revisités</li>' +
            '<li>un module de gestion des utilisateurs, comprenant une gestion des rôles et permissions plus adaptée</li>' +
            '<li>la possibilité d\'import et d\'export des données au format Excel</li>' +
            '<li>la possibilité d\'archiver les données</li>' +
            '<li>l\'envoi de mails automatique lors d\'étapes clés du processus de programmation</li>' +
            '<li>un questionnaire à tiroirs afin de pouvoir établir des enquêtes de satisfaction</li>' +
            '<li>un diagramme de Gantt servant à améliorer la visibilité travaux/programmation</li>' +
            '<li>un suivi des actions utilisateur avec historique</li>' +
            '<li>un onglet statistique avec des informations variées</li>' +
            '<li>...</li>' +
            '</ul>',
          todayText: 'Grâce à cette nouvelle version de "BIPE", la programmation du parcours client dans la région Est' +
            ' est devenue plus simple, plus claire et plus rapide.<br>' +
            'L\'automatisation a permis:' +
            '<ul>' +
            '<li>d\'augmenter le nombre de programmations traitées simultanément</li>' +
            '<li>d\'améliorer la visibilité des managers quant au travail de leur équipe</li>' +
            '<li>d\'améliorer la qualité du travail des équipes qui peuvent désormais se concentrer sur l\'essentiel</li>' +
            '<li>de réduire les délais de branchement au réseau gaz pour le client</li>' +
            '</ul>',
        },
        {
          title: 'Vulnérabilités',
          company: 'GRDF',
          year: '2018',
          previewText: 'Aide à la décision quant à l\'investissement sur le réseau gaz',
          folder: 'vulnerabilites',
          previewImage: 'ongletPilotage.png',
          images: [
            {
              src: 'ongletPilotage.png',
            },
            {
              src: 'moduleImport.png',
            },
            {
              src: 'moduleConfigImport.png',
            },
          ],
          presentationText: 'La maîtrise d’ouvrage de GRDF a en charge le choix des investissements sur les ouvrages' +
            ' d\'exploitation gaz, afin de garantir sécurité, performance, disponibilité gaz,' +
            ' accès aux nouveaux clients et modernisation.<br>' +
            'Chaque année, un budget fixe doit être dépensé le mieux possible sur le réseau gaz.<br><br>' +
            'Il s\'agit donc d\'accumuler un maximum de données significatives afin de pouvoir prioriser les investissements' +
            ' et ainsi investir de la façon la plus efficiente possible.',
          beforeText: 'Dans ce but, GRDF fait développer depuis 2015 l\'application web interne "Vulnérabilités".<br>' +
            'Cette application joue le rôle d\'interface entre plusieurs' +
            ' bases de données de plusieurs outils informatiques utilisés chez GDRF. Ceci en récupérant les' +
            ' données puis en les affichant de manière plus travaillée à l’aide de graphiques, requêtes filtrantes' +
            ' et d\'une mise en forme interactive.<br><br>',
          needText: 'Les imports de nouvelles données dans l\'outil sont quotidiens et anciennement fastidieux' +
            ' et longs pour la personne en charge.<br>' +
            'En effet cette dernière devait:' +
            '<ol>' +
            '<li>récupérer les données dans un format</li>' +
            '<li>mettre ces données au format csv</li>' +
            '<li>passer ce csv par un script afin de traiter/restructurer les données' +
            ' et découper le fichier en plusieurs fichiers plus petits</li>' +
            '<li>importer le/les fichier(s) dans "Vulnérabilités"</li>' +
            '<li>Attendre la fin de l\'importation pour lancer des routines sur les données</li>' +
            '<li>Multiplier les opérations 1 à 5 par le nombre de table à mettre à jour</li>' +
            '</ol>' +
            '<br>' +
            'De plus, aucune possibilité d\'import partiel.<br>' +
            'Toutes les donnnées devaient être supprimées puis ré-importées !<br><br>' +
            'L\'application commençant à engranger de plus en plus de données,' +
            ' et par la même, de plus en plus d\'utilisateurs, la nécessité d\'un nouveau mode d\'import devenait évidente.<br>' +
            'Il a alors été exprimé le besoin d\'un mode d\'import moins compliqué à effectuer, plus rapide et plus flexible.<br>' +
            'Une autre demande a été effectuée pour l\'ajout d\'un module de pilotage' +
            ' des données comprenant graphiques, tables, filtres et tri.<br>' +
            'Ainsi qu\'une demande pour la conception d\'un module de recherche avec affichage,' +
            ' tris et filtres.',
          afterText: 'Dans un premier temps ont été réalisés la conception et le développement d\'un nouveau module d\'import avec:' +
            '<ul>' +
            '<li>gestion des données à importer (table, champs, type de champs, ...)' +
            ' comprenant ajout, modification et suppression</li>' +
            '<li>nettoyage et restructuration des données avant import</li>' +
            '<li>affichage en temps réel des différentes étapes de l\'import</li>' +
            '<li>lancement automatique de routines sur les données avant et/ou après import</li>' +
            '<li>un temps d\'import divisé à minima par 10</li>' +
            '<li>possibilité d\'import complet ou partiel</li>' +
            '<li>historisation des imports déjà effectués</li>' +
            '<li>...</li>' +
            '</ul>' +
            '<br><br>' +
            'Puis dans un deuxième temps, Pierre FACQ Business s\'est chargé de la conception et du développement' +
            ' des modules de pilotage et recherche. Allant de l\'architecture des différentes tables en base de données,' +
            ' jusqu\'au développement des logiques et visuels permettant d\'exploiter la donnée.' +
            '<br><br>' +
            'Tout au long du travail effectué, l\'accent a été mis sur la rapidité des opérations de recherche.<br>' +
            '"Vulnérabilités" brassant énormément d\'informations de sources différentes, le défi résidait dans l\'optimisation des requêtes' +
            ' et des diverses techniques d\'exploitation des données.',
          todayText: 'À la suite de ces implémentations, "Vulnérabilités" a succité un intérêt certain chez GRDF.<br>' +
            'En 2019, un déploiement national de l\'application a été effectué par Pierre FACQ Business.<br>' +
            'Depuis, le nombre d\'utilisateurs ne cesse d\'augmenter et' +
            ' il émane fréquemment des demandes de maintenance ou développement visant à améliorer l\'outil.',
        },
        {
          title: 'e-Outillage',
          company: 'GRDF',
          year: '2016',
          previewText: 'Gestion et exploitation de l\'inventaire outillage',
          folder: 'eOutillage',
          previewImage: 'accueil.png',
          images: [
            {
              src: 'connexion.png',
            },
            {
              src: 'accueil.png',
            },
            {
              src: 'fichiers.png',
            },
          ],
          presentationText: 'Un nombre important d’outils est utilisé chaque jour par les exploitants au sein de l’entreprise GRDF.<br>' +
            'Ces outils appartiennent à des familles différentes (outillage, métrologie, banc de contrôle, …)' +
            ' et sont soumis à la législation.<br>' +
            'Il est du devoir de GRDF d’entreprendre un suivi régulier de son parc d\'outils ' +
            'afin de s’assurer que tout soit en règle.<br><br>' +
            'Les outils en circulation dans l’entreprise font donc l’objet d’entretiens réguliers afin' +
            ' de pouvoir être échangés sur les sites; affectés à des véhicules, des agents;' +
            ' ou encore être mis de côté pour défaillance.<br>',
          beforeText: 'Pour ce faire, les gestionnaires de chaque site gardaient à jour un fichier Excel.<br>',
          needText: 'La gestion par fichier Excel s\'est avérée être une méthode: <ul>' +
            '<li>coûteuse en temps (lors de recherches, modifications, ajouts, ...)</li>' +
            '<li>affichant des problèmes d\'harmonisation entre sites</li>' +
            '<li>nécessitant le passage par d\'autres canaux (téléphone)</li>' +
            '<li>ne permettant pas la traçabilité des actions</li>' +
            '<li>non sécurisée (modification ou suppression du fichier)</li>' +
            '</ul>' +
            '<br><br>' +
            'C\'est donc rapidement que s\'est fait ressentir le besoin d\'une base outillage commune,' +
            ' dont GRDF a demandé la mise en place dans la foulée via la création d\'une application web interne.',
          afterText: 'En reprenant les besoins en détail et dans une optique de satisfaction client avant tout,' +
            ' Pierre FACQ Business a conçu puis développé une application web PHP avec:' +
            '<ul>' +
            '<li>un modèle d\'utilisation simple comprenant un système de recherche par catégorie inspiré des sites de e-commerce</li>' +
            '<li>des fonctionnalités répondant aux besoins métier (import de fichiers divers, export au format Excel, ...)</li>' +
            '<li>une gestion des utilisateurs (incluant rôle et localisation)</li>' +
            '<li>une gestion des accès via un panneau de connexion sécurisé</li>' +
            '<li>un traçage des actions de création, modification et suppression</li>' +
            '<li>un système d\'archivage des données</li>' +
            '<li>...</li>' +
            '</ul>',
          todayText: 'L\'application a eu un fort succès au sein de GRDF.<br>' +
            'GRDF a depuis mis en place une solution interne à périmètre national inspirée en partie des fonctionnalités de "e-Outillage".',
        },
      ],
    }),
    methods: {
      showImageFullScreen (folder, image) {
        this.showImage = true
        this.imageLink = 'projects/' + folder + '/' + image
      },
      hideImageFullScreen () {
        this.showImage = false
      },
      // empeche de scroll quand le modal est ouvert
      hideScroll () {
        document.documentElement.classList.add('overflow-y-hidden')
      },
      // Redonne la possibilité de scroll quand le modal est fermé
      showScroll () {
        document.documentElement.classList.remove('overflow-y-hidden')
      },
      hideModal () {
        this.showScroll()
        this.displayModal = false
        this.modalProject = null
      },
      showModal (project) {
        this.hideScroll()
        this.displayModal = true
        this.modalProject = project
      },
    },
  }
</script>
<style lang="sass">
#closeBtn
  position: fixed
  top: 1%
  right: 1%
#projectModal
  overflow-y: auto
  align-items: start
#projectModal .v-overlay__scrim
  position: fixed
#projectModal .v-overlay__content
  width: 100%
#bipe .scroll-y-reverse-transition-enter-active
  transition-delay: 0.2s !important
#vulnerabilites .scroll-y-reverse-transition-enter-active
  transition-delay: 0.4s !important
#eOutillage .scroll-y-reverse-transition-enter-active
  transition-delay: 0.6s !important
</style>
